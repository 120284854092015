import { h } from 'preact';
import ApiKeyProvider from '../api-key-provider';
import AlertProvider from '../alert-provider';
import RequestForm from '../request-form';
import Alert from '../alert';
import style from './style.scss';
var Widget = function (_a) {
    var fmcWidgetApiKey = _a.fmcWidgetApiKey;
    return (h("div", { class: style['fmc-widget'] },
        h(ApiKeyProvider, { fmcWidgetApiKey: fmcWidgetApiKey },
            h(AlertProvider, null,
                h(RequestForm, null),
                h(Alert, null)))));
};
export default Widget;
