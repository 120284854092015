import sendRequest from './sendRequest';
var FACILITIES_QUERY = "query getFacilities($limit: Int) {\n  facilities(limit: $limit) {\n    _id\n    name   \n  }\n}";
var getFacilities = function (token) {
    return sendRequest({
        query: FACILITIES_QUERY,
        token: token,
        variables: { limit: 1000 },
    });
};
export default getFacilities;
