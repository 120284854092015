import { h } from 'preact';
import { useMemo, useContext } from 'preact/hooks';
import classnames from 'classnames';
import { AlertContext } from '../alert-provider';
import useAlert from '../../hooks/useAlert';
import style from './style.scss';
var Alert = function (_a) {
    var _b;
    var _c = useAlert(), options = _c.options, close = _c.close;
    var _d = options || {}, _e = _d.type, type = _e === void 0 ? 'SUCCESS' : _e, title = _d.title, message = _d.message;
    var context = useContext(AlertContext);
    var isSuccess = useMemo(function () { return type === 'SUCCESS'; }, [type]);
    if (!context.options)
        return null;
    return (h("div", { class: classnames(style['fmc-alert'], (_b = {}, _b[style.error] = !isSuccess, _b)) },
        h("div", { class: style['fmc-alert-content'] },
            title && h("h1", { className: style['fmc-alert-title'] }, title),
            message && h("p", { class: style['fmc-alert-message'] }, message),
            h("button", { onClick: function () { return close(); }, className: style['fmc-alert-button'] }, isSuccess ? 'OK' : 'Try again'))));
};
export default Alert;
