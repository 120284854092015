// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".fmc-widget__25zl-{position:relative}\n", "",{"version":3,"sources":["/Users/shaun/Desktop/dev/widget-request-form/src/components/widget/style.scss"],"names":[],"mappings":"AAAA,mBACE,iBAAkB","file":"style.scss","sourcesContent":[".fmc-widget {\n  position: relative;\n}"]}]);
// Exports
exports.locals = {
	"fmc-widget": "fmc-widget__25zl-"
};
module.exports = exports;
