import { h, createContext } from 'preact';
import { useState } from 'preact/hooks';
import style from './style.scss';
export var ApiKeyContext = createContext(null);
var ApiKeyProvider = function (_a) {
    var fmcWidgetApiKey = _a.fmcWidgetApiKey, children = _a.children;
    var _b = useState(null), isValid = _b[0], setIsValid = _b[1];
    if (!fmcWidgetApiKey) {
        var searchUrlParams = new URLSearchParams(window.location.search);
        fmcWidgetApiKey = searchUrlParams.get('fmcWidgetApiKey');
    }
    if (!fmcWidgetApiKey)
        return h("div", { class: style['fmc-error'] }, "Token is not provided.");
    return (h(ApiKeyContext.Provider, { value: { apiKey: fmcWidgetApiKey, setIsValid: setIsValid, isValid: isValid } }, children));
};
export default ApiKeyProvider;
