import sendRequest from './sendRequest';
var FACILITY_QUERY = "\n  query getFacility($facilityId: ID!) {\n    facility(_id: $facilityId) {\n      services {\n        _id\n        name\n        children {\n          _id\n          name\n        }\n      }\n      areas {\n        name\n        children {\n          name\n          children {\n            name\n          }\n        }\n      }\n    }\n  }\n";
var getFacility = function (facilityId, token) {
    return sendRequest({
        query: FACILITY_QUERY,
        variables: { facilityId: facilityId },
        token: token,
    });
};
export default getFacility;
