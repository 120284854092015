import sendRequest from './sendRequest';
import getFileExtension from './getFileExtension';
var GET_SIGNED_URL_MUTATION = "\n  mutation getSignedUrl($input: GetSignedUrlInput!){\n    getSignedUrl(input: $input) {\n      fileName\n      url\n      contentType\n    }\n  }\n";
var uploadPhotos = function (_a) {
    var token = _a.token, photos = _a.photos;
    return new Promise(function (resolveUpload, reject) {
        if (!photos.length)
            return resolveUpload([]);
        var photoPromises = photos.map(function (photo) {
            return new Promise(function (resolve) {
                sendRequest({
                    query: GET_SIGNED_URL_MUTATION,
                    variables: {
                        input: {
                            contentType: getFileExtension(photo.name),
                        },
                    },
                    token: token,
                })
                    .then(function (res) {
                    var _a = res.data.getSignedUrl || {}, url = _a.url, fileName = _a.fileName, contentType = _a.contentType;
                    fetch(url, {
                        method: 'PUT',
                        body: photo,
                    })
                        .then(function () {
                        return resolve({
                            name: photo.name,
                            key: fileName,
                            fileType: contentType,
                            size: photo.size,
                            uploadedAt: new Date(),
                        });
                    })
                        .catch(reject);
                })
                    .catch(reject);
            });
        });
        return Promise.all(photoPromises)
            .then(function (res) { return resolveUpload(res); })
            .catch(reject);
    });
};
export default uploadPhotos;
