var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { h } from 'preact';
import { useCallback, useState, useRef, useEffect } from 'preact/hooks';
import classnames from 'classnames';
import genId from '../../helpers/genId';
import style from './style.scss';
var VALID_TYPES = ['image/jpg', 'image/png', 'image/jpeg'];
var Dropzone = function (_a) {
    var _b;
    var label = _a.label, onChange = _a.onChange, photos = _a.photos;
    var fileInputRef = useRef(null);
    var timerRef = useRef(null);
    var _c = useState([]), previewPhotos = _c[0], setPreviewPhotos = _c[1];
    var _d = useState(false), isDragActive = _d[0], setIsDragActive = _d[1];
    var _e = useState(null), dropAreaError = _e[0], setDropAreaError = _e[1];
    var handleDragOver = useCallback(function (e) {
        e.preventDefault();
        setIsDragActive(true);
    }, []);
    var handleDragEnter = useCallback(function (e) {
        e.preventDefault();
        setIsDragActive(true);
    }, []);
    var handleDragLeave = useCallback(function (e) {
        e.preventDefault();
        setIsDragActive(false);
    }, []);
    var handleClick = useCallback(function () {
        if (fileInputRef.current)
            fileInputRef.current.click();
    }, []);
    var handleError = useCallback(function (_a) {
        var _b = _a.type, type = _b === void 0 ? 'ERROR' : _b, message = _a.message;
        setDropAreaError({ type: type, message: message });
        if (timerRef.current)
            clearTimeout(timerRef.current);
        timerRef.current = setTimeout(function () {
            setDropAreaError(null);
            timerRef.current = null;
        }, 3000);
    }, []);
    var handleFiles = useCallback(function (acceptedFiles) {
        if (acceptedFiles[0]) {
            var invalidFiles = acceptedFiles.filter(function (_a) {
                var type = _a.type;
                return !VALID_TYPES.includes(type);
            });
            var validFiles = acceptedFiles.filter(function (_a) {
                var type = _a.type;
                return VALID_TYPES.includes(type);
            });
            if (invalidFiles.length) {
                if (invalidFiles.length === acceptedFiles.length) {
                    handleError({
                        message: 'Please upload JPG or PNG files.',
                    });
                }
                else {
                    handleError({
                        type: 'WARNING',
                        message: 'Some of your files have invalid type.',
                    });
                }
            }
            var acceptedPreviewPhotos = validFiles.map(function (file) { return ({
                url: URL.createObjectURL(file),
                name: file.name,
            }); });
            onChange(__spreadArrays(photos, validFiles));
            setPreviewPhotos(__spreadArrays(previewPhotos, acceptedPreviewPhotos));
        }
    }, [handleError, onChange, photos, previewPhotos]);
    var handleDrop = useCallback(function (e) {
        var _a;
        e.preventDefault();
        var acceptedFiles = Array.from(((_a = e.dataTransfer) === null || _a === void 0 ? void 0 : _a.files) || []);
        handleFiles(acceptedFiles);
        setIsDragActive(false);
    }, [handleFiles]);
    var handleFilesInput = useCallback(function (e) {
        var files = e.target.files;
        handleFiles(Array.from(files || []));
    }, [handleFiles]);
    var handleRemove = useCallback(function (e) {
        e.preventDefault();
        var value = e.target.value;
        var newPhotos = __spreadArrays(photos);
        var newPreviewPhotos = __spreadArrays(previewPhotos);
        newPhotos.splice(parseInt(value), 1);
        newPreviewPhotos.splice(parseInt(value), 1);
        onChange(newPhotos);
        setPreviewPhotos(newPreviewPhotos);
    }, [onChange, photos, previewPhotos]);
    useEffect(function () {
        if (!photos.length)
            setPreviewPhotos([]);
    }, [photos]);
    return (h("div", { class: style.dropzone },
        h("label", { class: style['dropzone-label'], htmlFor: "#dropzone-" + label }, label),
        h("div", { onDragOver: handleDragOver, onDragEnter: handleDragEnter, onDragLeave: handleDragLeave, onDrop: handleDrop, onClick: handleClick, class: style['dropzone-container'] },
            h("input", { type: "file", accept: VALID_TYPES.join(', '), multiple: true, ref: fileInputRef, class: style['dropzone-input'], id: "dropzone-" + label, onChange: handleFilesInput }),
            h("div", { class: style['dropzone-area'] },
                h("div", { class: style['message'] },
                    isDragActive
                        ? 'Drop the documents here ...'
                        : 'Drag & drop some documents here, or click to select files.',
                    dropAreaError && (h("div", { class: classnames(style['error-message'], (_b = {},
                            _b[style['warning']] = dropAreaError.type === 'WARNING',
                            _b)) }, dropAreaError.message))))),
        h("div", { class: style['dropzone-preview'] }, previewPhotos.map(function (_a, index) {
            var name = _a.name, url = _a.url;
            return (h("div", { key: genId(index), class: style['dropzone-preview-item'] },
                h("button", { onClick: handleRemove, value: index, className: style['preview-close'] }, "\u00D7"),
                h("img", { class: style['preview-image'], alt: "preview for " + name, src: url }),
                h("span", { class: style['preview-label'] }, name)));
        }))));
};
export default Dropzone;
