import { useContext, useCallback } from 'preact/hooks';
import { AlertContext } from '../components/alert-provider';
var useAlert = function () {
    var _a = useContext(AlertContext), setOptions = _a.setOptions, options = _a.options;
    var alert = useCallback(function (_a) {
        var _b = _a.type, type = _b === void 0 ? 'SUCCESS' : _b, title = _a.title, message = _a.message;
        return setOptions({
            type: type,
            title: title,
            message: message,
        });
    }, [setOptions]);
    var close = useCallback(function () { return setOptions(null); }, [setOptions]);
    return {
        options: options,
        alert: alert,
        close: close,
    };
};
export default useAlert;
