var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { h } from 'preact';
import classnames from 'classnames';
import style from './style.scss';
var Select = function (_a) {
    var _b, _c;
    var label = _a.label, _d = _a.options, options = _d === void 0 ? [] : _d, name = _a.name, required = _a.required, isLoading = _a.isLoading, disabled = _a.disabled, notSelected = _a.notSelected, props = __rest(_a, ["label", "options", "name", "required", "isLoading", "disabled", "notSelected"]);
    return (h("div", { class: classnames(style['fmc-select'], (_b = {}, _b[style.disabled] = disabled, _b), 'fmc-widget-select', (_c = {},
            _c['fmc-widget-select-disabled'] = disabled,
            _c)) },
        h("div", { class: style['fmc-select-label'] }, label),
        h("div", { class: style['fmc-select-container'] },
            h("select", __assign({ disabled: disabled, required: required, name: name, class: style['fmc-select-field'] }, props),
                h("option", { value: "", disabled: !notSelected && options.length !== 0 }, options[0] ? 'Not selected' : 'No options'),
                options.map(function (_a) {
                    var label = _a.label, value = _a.value;
                    return (h("option", { key: name + "-" + label + "-" + value, value: value }, label));
                })),
            isLoading && (h("div", { class: style['loading-container'] },
                h("div", { class: style.loader }))))));
};
export default Select;
