var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { h } from 'preact';
import { useState, useCallback, useEffect, useMemo } from 'preact/hooks';
import humanizeDuration from 'humanize-duration';
import Input from '../input';
import Select from '../select';
import TextArea from '../textarea';
import Dropzone from '../dropzone';
import useAlert from '../../hooks/useAlert';
import useApiKey from '../../hooks/useApiKey';
import getFacilities from '../../helpers/getFacilities';
import getFacility from '../../helpers/getFacility';
import getTimeframes from '../../helpers/getTimeframes';
import createRequest from '../../helpers/createRequest';
import uploadPhotos from '../../helpers/uploadPhotos';
import mapAreasToOptions from '../../helpers/mapAreasToOptions';
import style from './style.scss';
var RequestForm = function () {
    var _a = useApiKey(), apiKey = _a.apiKey, setIsValid = _a.setIsValid, isValid = _a.isValid;
    var alert = useAlert().alert;
    var _b = useState([]), facilities = _b[0], setFacilities = _b[1];
    var _c = useState([]), areas = _c[0], setAreas = _c[1];
    var _d = useState([]), services = _d[0], setServices = _d[1];
    var _e = useState([]), timeframesOptions = _e[0], setTimeframesOptions = _e[1];
    var _f = useState(false), isLoading = _f[0], setIsLoading = _f[1];
    var _g = useState(false), isFacilityLoading = _g[0], setIsFacilityLoading = _g[1];
    var _h = useState(''), summary = _h[0], setSummary = _h[1];
    var _j = useState(''), email = _j[0], setEmail = _j[1];
    var _k = useState('Standard'), priority = _k[0], setPriority = _k[1];
    var _l = useState(''), building = _l[0], setBuilding = _l[1];
    var _m = useState(''), level = _m[0], setLevel = _m[1];
    var _o = useState(''), area = _o[0], setArea = _o[1];
    var _p = useState(''), identifier = _p[0], setIdentifier = _p[1];
    var _q = useState(''), service = _q[0], setService = _q[1];
    var _r = useState(''), subService = _r[0], setSubService = _r[1];
    var _s = useState(''), description = _s[0], setDescription = _s[1];
    var _t = useState(''), name = _t[0], setName = _t[1];
    var _u = useState([]), photos = _u[0], setPhotos = _u[1];
    var facilitiesOptions = useMemo(function () { return facilities === null || facilities === void 0 ? void 0 : facilities.map(function (_a) {
        var _id = _a._id, name = _a.name;
        return ({ label: name, value: _id });
    }); }, [facilities]);
    var selectedService = useMemo(function () { return services === null || services === void 0 ? void 0 : services.find(function (_a) {
        var _id = _a._id;
        return _id === service;
    }); }, [
        services,
        service,
    ]);
    var serviceOptions = useMemo(function () { return services === null || services === void 0 ? void 0 : services.map(function (_a) {
        var _id = _a._id, name = _a.name;
        return ({ label: name, value: _id });
    }); }, [services]);
    var subServiceOptions = useMemo(function () { var _a; return (_a = selectedService === null || selectedService === void 0 ? void 0 : selectedService.children) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
        var _id = _a._id, name = _a.name;
        return ({ label: name, value: _id });
    }); }, [selectedService]);
    var levelOptions = useMemo(function () { return mapAreasToOptions(areas); }, [areas]);
    var selectedLevel = useMemo(function () { return areas === null || areas === void 0 ? void 0 : areas.find(function (_a) {
        var name = _a.name;
        return name === level;
    }); }, [
        areas,
        level,
    ]);
    var areaOptions = useMemo(function () { return mapAreasToOptions(selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.children); }, [
        selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.children,
    ]);
    var selectedArea = useMemo(function () { var _a; return (_a = selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.children) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var name = _a.name;
        return name === area;
    }); }, [area, selectedLevel === null || selectedLevel === void 0 ? void 0 : selectedLevel.children]);
    var identifierOptions = useMemo(function () { return mapAreasToOptions(selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.children); }, [
        selectedArea === null || selectedArea === void 0 ? void 0 : selectedArea.children,
    ]);
    var handleError = useCallback(function () { return setIsValid(false); }, [setIsValid]);
    var resetForm = useCallback(function () {
        setSummary('');
        setEmail('');
        setPriority('Standard');
        setBuilding('');
        setLevel('');
        setArea('');
        setIdentifier('');
        setService('');
        setSubService('');
        setDescription('');
        setName('');
        setPhotos([]);
    }, []);
    var handleChange = useCallback(function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        switch (name) {
            case 'summary':
                return setSummary(value);
            case 'email':
                return setEmail(value);
            case 'description':
                return setDescription(value);
            case 'name':
                return setName(value);
            case 'priority':
                return setPriority(value);
            case 'service':
                setService(value);
                return setSubService('');
            case 'subService':
                return setSubService(value);
            case 'building':
                setService('');
                setSubService('');
                setLevel('');
                setArea('');
                setIdentifier('');
                return setBuilding(value);
            case 'level':
                setArea('');
                setIdentifier('');
                return setLevel(value);
            case 'area':
                setIdentifier('');
                return setArea(value);
            case 'identifier':
                return setIdentifier(value);
        }
    }, []);
    var handleAddPhotos = useCallback(function (files) { return setPhotos(files); }, []);
    var handleSubmit = useCallback(function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var breakLine, createdBy, variables, uploadedPhotos, createRequestResponse, createRequestData, createRequestMutation, code, errors_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    setIsLoading(true);
                    breakLine = name && description ? '\n\n' : '';
                    createdBy = name ? "Created by: " + name : '';
                    variables = {
                        facilityId: building,
                        request: {
                            name: summary,
                            priority: priority,
                            service: {
                                _id: service,
                            },
                            description: description + breakLine + createdBy,
                        },
                    };
                    if (subService)
                        variables.request.subservice = { _id: subService };
                    if (email)
                        variables.request.createdByEmail = email;
                    if (level) {
                        variables.request.level = { name: level };
                        if (area) {
                            variables.request.area = { name: area };
                            if (identifier) {
                                variables.request.identifier = { name: identifier };
                            }
                        }
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, uploadPhotos({ token: apiKey, photos: photos })];
                case 2:
                    uploadedPhotos = _b.sent();
                    if ((_a = uploadedPhotos) === null || _a === void 0 ? void 0 : _a.length) {
                        variables.request.files = uploadedPhotos;
                    }
                    return [4 /*yield*/, createRequest({
                            variables: variables,
                            token: apiKey,
                        })];
                case 3:
                    createRequestResponse = _b.sent();
                    createRequestData = (createRequestResponse || {}).data;
                    createRequestMutation = (createRequestData || {}).createRequest;
                    code = (createRequestMutation || {}).code;
                    alert({
                        title: code ? "Work order #" + code + " was created" : 'Work order was created.',
                    });
                    resetForm();
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    errors_1 = _b.sent();
                    alert({
                        type: 'ERROR',
                        title: 'Error',
                        message: Array.isArray(errors_1)
                            ? errors_1.map(function (_a) {
                                var message = _a.message;
                                return message;
                            }).join('; ')
                            : errors_1.message,
                    });
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [
        name,
        building,
        summary,
        priority,
        service,
        subService,
        description,
        email,
        level,
        area,
        identifier,
        apiKey,
        photos,
        alert,
        resetForm,
    ]);
    useEffect(function () {
        getFacilities(apiKey)
            .then(function (res) {
            var fetchedFacilities = res.data.facilities;
            setFacilities(fetchedFacilities);
            setLevel('');
            setArea('');
            setIdentifier('');
            setIsValid(true);
        })
            .catch(handleError);
        getTimeframes(apiKey)
            .then(function (res) {
            var fetchedTimeframes = res.data.me.team.timeframes;
            setTimeframesOptions(Object.entries(fetchedTimeframes).map(function (_a) {
                var key = _a[0], value = _a[1];
                return ({
                    label: key + " (" + humanizeDuration(value * 1000, { largest: 1 }) + ")",
                    value: key,
                });
            }));
        })
            .catch(handleError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        setAreas([]);
        setServices([]);
        if (building && apiKey) {
            setIsFacilityLoading(true);
            getFacility(building, apiKey)
                .then(function (res) {
                var _a;
                var _b = res.data.facility, fetchedAreas = _b.areas, fetchedServices = _b.services;
                setAreas(fetchedAreas);
                setLevel('');
                setArea('');
                setIdentifier('');
                setServices(fetchedServices);
                setService((_a = fetchedServices[0]) === null || _a === void 0 ? void 0 : _a._id);
                setSubService('');
            })
                .catch(handleError)
                .finally(function () { return setIsFacilityLoading(false); });
        }
    }, [building, apiKey, handleError]);
    if (isValid === null)
        return null;
    if (isValid === false) {
        return h("div", { class: style['fmc-error'] }, "There is a problem with Work Order form loading.");
    }
    return (h("form", { class: style['fmc-request-form'], onSubmit: handleSubmit },
        h("h6", { class: style['fmc-request-form-heading'] }, "Your Details"),
        h(Input, { required: true, name: "summary", label: "Summary", onInput: handleChange, value: summary }),
        h(Input, { type: "email", name: "email", label: "Email", onInput: handleChange, value: email }),
        h("h6", { class: style['fmc-request-form-heading'] }, "Job Details"),
        h(Select, { name: "priority", options: timeframesOptions, label: "Priority", value: priority, onInput: handleChange, required: true }),
        h(Select, { name: "building", options: facilitiesOptions, label: "Site / Building", value: building, onInput: handleChange, required: true, notSelected: true }),
        h("div", { className: style['fmc-area-selects'] },
            h(Select, { name: "level", options: levelOptions, label: "Area Tier 1", value: level, onInput: handleChange, isLoading: isFacilityLoading, disabled: !(levelOptions === null || levelOptions === void 0 ? void 0 : levelOptions.length) || isFacilityLoading, notSelected: true }),
            h(Select, { name: "area", options: areaOptions, label: "Area Tier 2", value: area, onInput: handleChange, isLoading: isFacilityLoading, disabled: !(areaOptions === null || areaOptions === void 0 ? void 0 : areaOptions.length) || isFacilityLoading, notSelected: true }),
            h(Select, { name: "identifier", options: identifierOptions, label: "Area Tier 3", value: identifier, onInput: handleChange, isLoading: isFacilityLoading, disabled: !(identifierOptions === null || identifierOptions === void 0 ? void 0 : identifierOptions.length) || isFacilityLoading, notSelected: true })),
        h(Select, { name: "service", options: serviceOptions, label: "Service", value: service, onInput: handleChange, required: true, disabled: !(serviceOptions === null || serviceOptions === void 0 ? void 0 : serviceOptions.length) || isFacilityLoading, isLoading: isFacilityLoading }),
        h(Select, { name: "subService", options: subServiceOptions, label: "Sub-Service", value: subService, onInput: handleChange, disabled: !(subServiceOptions === null || subServiceOptions === void 0 ? void 0 : subServiceOptions.length) || isFacilityLoading, isLoading: isFacilityLoading, notSelected: true }),
        h(TextArea, { name: "description", label: "Description", value: description, onInput: handleChange }),
        h(Input, { type: "name", name: "name", label: "Name", onInput: handleChange, value: name }),
        h(Dropzone, { label: "Documents", onChange: handleAddPhotos, photos: photos }),
        h("button", { disabled: isLoading, class: style['fmc-request-form-submit'], type: "submit" },
            "Create work order",
            isLoading && h("div", { class: style.loader }))));
};
export default RequestForm;
