// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".fmc-input__2VDag{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-bottom:10px}.fmc-input__2VDag.empty__3VbBC{opacity:0.6}.fmc-input__2VDag.disabled__5dj84{opacity:0.3}.fmc-input__2VDag .fmc-input-label__2eOW9{margin-bottom:5px}.fmc-input__2VDag .fmc-input-field__3pCW1{padding:5px;border:1px solid #919191;border-radius:3px;padding:10px}\n", "",{"version":3,"sources":["/Users/shaun/Desktop/dev/widget-request-form/src/components/input/style.scss","/Users/shaun/Desktop/dev/widget-request-form/src/styles/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,mBAAa,CAAb,YAAa,CACb,yBAAsB,CAAtB,qBAAsB,CACtB,kBAAmB,CAHrB,+BAMI,WAAY,CANhB,kCAUI,WAAY,CAVhB,0CAcI,iBAAkB,CAdtB,0CAkBI,WAAY,CACZ,wBCd0B,CDe1B,iBAAkB,CAClB,YAAa","file":"style.scss","sourcesContent":["@import '../../styles/variables';\n\n.fmc-input {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n\n  &.empty {\n    opacity: 0.6;\n  }\n\n  &.disabled {\n    opacity: 0.3;\n  }\n  \n  .fmc-input-label {\n    margin-bottom: 5px;\n  }\n  \n  .fmc-input-field {\n    padding: 5px;\n    border: 1px solid $border;\n    border-radius: 3px;\n    padding: 10px;\n  }\n}","$white: #fff;\n\n$primary: #444;\n$primary-dark: darken($primary, 0.01);\n$primary-heading: lighten($primary, 6.8);\n$primary-light: lighten($primary, 20);\n\n$border: lighten($primary, 30);\n\n$backdrop: rgba(0,0,0,0.3);\n\n$glass: rgba(255, 255, 255, 0.75);\n\n$error: #ff6666;\n$success: #55dd55;\n$warning: #ffcc77;\n\n$font-family-base: -apple-system,\nBlinkMacSystemFont,\n'Segoe UI',\nRoboto,\n'Helvetica Neue',\nArial,\n'Noto Sans',\nsans-serif,\n'Apple Color Emoji',\n'Segoe UI Emoji',\n'Segoe UI Symbol',\n'Noto Color Emoji';"]}]);
// Exports
exports.locals = {
	"fmc-input": "fmc-input__2VDag",
	"empty": "empty__3VbBC",
	"disabled": "disabled__5dj84",
	"fmc-input-label": "fmc-input-label__2eOW9",
	"fmc-input-field": "fmc-input-field__3pCW1"
};
module.exports = exports;
