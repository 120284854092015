// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".fmc-error__1v-_b{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#444;font-family:-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\";padding:20px}\n", "",{"version":3,"sources":["/Users/shaun/Desktop/dev/widget-request-form/src/components/api-key-provider/style.scss","/Users/shaun/Desktop/dev/widget-request-form/src/styles/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,mBAAa,CAAb,YAAa,CACb,oBAAuB,CAAvB,sBAAuB,CACvB,qBAAmB,CAAnB,kBAAmB,CACnB,UCJY,CDKZ,sLCqBgB,CDpBhB,YAAa","file":"style.scss","sourcesContent":["@import '../../styles/variables';\n\n.fmc-error {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: $primary;\n  font-family: $font-family-base;\n  padding: 20px;\n}","$white: #fff;\n\n$primary: #444;\n$primary-dark: darken($primary, 0.01);\n$primary-heading: lighten($primary, 6.8);\n$primary-light: lighten($primary, 20);\n\n$border: lighten($primary, 30);\n\n$backdrop: rgba(0,0,0,0.3);\n\n$glass: rgba(255, 255, 255, 0.75);\n\n$error: #ff6666;\n$success: #55dd55;\n$warning: #ffcc77;\n\n$font-family-base: -apple-system,\nBlinkMacSystemFont,\n'Segoe UI',\nRoboto,\n'Helvetica Neue',\nArial,\n'Noto Sans',\nsans-serif,\n'Apple Color Emoji',\n'Segoe UI Emoji',\n'Segoe UI Symbol',\n'Noto Color Emoji';"]}]);
// Exports
exports.locals = {
	"fmc-error": "fmc-error__1v-_b"
};
module.exports = exports;
